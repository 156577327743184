<template>
    <div class="hero-slider-section position-relative container-fluid" v-if="slider && slider.earthlings_slider_item">
        <div class="row">
            <div class="col-12 p-0">
               <!--<div class="swiper-container position-relative">
                    <div class="swiper-wrapper">
                        <div 
                            class="swiper-slide" 
                            :class="{'swiper-slide-active': currentSlide == s2ind}"
                            v-for="(slide, s2ind) in slider.earthlings_slider_item" :key="slide.id"
                        >
                            <div class="text-container" v-html="slide.description"></div>
                            <img :src="sliderImg(slide)" class="d-block" alt="...">
                        </div> 
                        <div class="swiper-slide">Slide 2</div>
                        <div class="swiper-slide">Slide 3</div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="position-absolute hero-slider-section-arrows d-none d-lg-flex flex-row justify-content-around align-items-center mr-2">
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                    <div class="position-absolute w-100 arrows--mobile d-flex d-lg-none flex-row justify-content-between align-items-center">
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                    <div>
                        <router-link :to="{ name: `ContactBrossure_${selectedLangCode}`}"
                            class="text-dark text-uppercase position-absolute download-btn px-5 py-3 bg-white"
                        >                            
                            {{ $store.state.translation.el_download_the_brossure }}
                            <img src="../assets/icons/arrow-download.svg" class="img-fluid mb-1">
                        </router-link>
                    </div>
               </div>-->
               
                <div id="homepageSlider" class="carousel slide position-relative" data-ride="carousel">
                    <ol class="carousel-indicators align-items-end">                        
                        <li v-for="(slide, sind) in slider.earthlings_slider_item" :key="slide.id"
                            data-target="#homepageSlider" 
                            :data-slide-to="sind"
                            :class="{'active': currentSlide == sind}"
                        ></li>
                    </ol>
                    <div class="carousel-inner">
                        <div 
                            class="carousel-item" 
                            :class="{'active': currentSlide == s2ind}"
                            v-for="(slide, s2ind) in slider.earthlings_slider_item" :key="slide.id"
                        >
                            <!--<div class="col-12 col-lg-8 text-container" v-html="slide.description"></div>-->
                            <img :src="sliderImg(slide)" class="d-block w-100" alt="...">
                        </div>                        
                    </div>
                    <!--<div>
                        <router-link :to="{ name: `ContactBrossure_${selectedLangCode}`}"
                            class="text-dark position-absolute download-btn px-4 py-2 bg-white"
                        >                            
                            {{ $store.state.translation.el_download_the_brossure }}
                            <img src="../assets/icons/arrow-download.svg" class="img-fluid mb-1 ml-1">
                        </router-link>
                    </div>-->
                    <div class="position-absolute hero-slider-section-arrows d-none d-md-flex flex-row justify-content-between align-items-center">
                        <span class="carousel-control-prev-icon" data-target="#homepageSlider" data-slide="prev"></span>
                        <span class="carousel-control-next-icon" data-target="#homepageSlider" data-slide="next"></span>
                    </div>
                    <div class="position-absolute w-100 arrows--mobile d-flex d-md-none flex-row justify-content-between align-items-center">
                        <span class="carousel-control-prev-icon ml-2" data-target="#homepageSlider" data-slide="prev"></span>
                        <span class="carousel-control-next-icon mr-2" data-target="#homepageSlider" data-slide="next"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// Import Swiper Vue.js components
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([ Navigation, Pagination ])
//import "swiper/css/pagination";
// import required modules
//import { Pagination } from "swiper";

export default {
  name: "HeroSliderSection",
  props:['slider'],
  data() {
    return {
      pages: [],
      currentSlide: 0
    }
  },
  methods:{
    sliderImg (slide){
        return slide.image ? this.apiUrl + '/' + slide.image.path +  slide.image.file_name : ""
    }
  },
  mounted(){
    new Swiper('.swiper-container', {
      loop: true,
      slidesPerView: "auto",
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  }
}
</script>

<style scoped>

</style>